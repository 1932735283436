export const agruparAsientos = (asientos) => {
    let respuesta = [];
    let existeRegistro = false;

    if(asientos.length > 0){

        asientos.map(rsp => {
            //Si el registro tiene ejecución exitosa agrupo
            if(rsp.rspCode === 0){
                //Verifico si el registro existe mediante execution_date y asiento_type
                existeRegistro = respuesta.some(
                    data => data.executionDate+data.asientoType ===  rsp.executionDate+rsp.asientoType && data.rspCode === 0
                );
                //Si no existe lo agrego
                if(!existeRegistro){
                    respuesta.push(rsp);
                }
            } else{
                //Si existe más de un registro considerando asiento_type, glosa y ejecución_date
                //y al menos uno de ellos fue enviado a reprocesar, aquellos con error no son desplegados.
                existeRegistro = asientos.some(
                    data => data.executionDate+data.asientoType+data.glosa === rsp.executionDate+rsp.asientoType+rsp.glosa && data.status===0
                );
                if(!existeRegistro){
                    respuesta.push(rsp);
                }
            }
        })
    }

    return respuesta;
};