import React, { Component, Fragment } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import {
    DataGrid,
    GridToolbarContainer,
    // GridToolbarFilterButton,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { TextField, Button, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import 'dayjs/locale/es';

import { EntriesBox, EntriesContainer, Cuncuna, IconLoading } from '../styles';


const API_URL_EXECUTION =  process.env.REACT_APP_API_URL_EXECUTION;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;

const currencyFormatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
});

const clpPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            <GridToolbarExport csvOptions={{ delimiter: ";" }} />
        </GridToolbarContainer>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <div style={{ margin: 'auto' }}>Sin datos</div>
    );
}


class ReportsAccountingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateValue: null,
            disabledButton: true,

            cuncuna: false,
            disabledReport: true,

            disabledSeat: false,
            seatSelected: "",
            seat: [],


            isShowModal: false,
            messageError: "",

            //REPORTE
            rows: [],
            columns: []

        }
    }

    componentDidMount(d) {
        let endpoint = `${API_URL_EXECUTION}/findAsientos`;
        this.handleSeat(endpoint, d);
    }

    handleSeat = (endpoint, d) => {
        let searchSeat = [];
        this.setState({disabledSeat: false, disabledButton:true,  seatSelected: "", seat: []})

        let request = '{';
        request = request + '"fecha": "' + d + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,   
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                response.respuesta.map((s) => {
                    return searchSeat.push({ 'name': s, 'value': s });
                });
                this.setState({ seat: searchSeat });
            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }


    handleDateChange = (e) => {
        this.setState({ dateValue: e, disabledButton: (this.state.seatSelected !== "") ? false : true });
    }

    handleChangeSeat = (e) => {
        this.setState({ seatSelected: e.target.value, disabledButton: (this.state.dateValue !== null) ? false : true });
    }

    handleClose = () => {
        this.setState({ isShowModal: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ cuncuna: true });

        let endpoint = `${API_URL_EXECUTION}/reporteAsientosContables`;
        let request = '{';
        request = request + '"fecha": "' + this.state.dateValue + '", ';
        request = request + '"asiento": "' + this.state.seatSelected + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {

                if (response.result === 0) {
                    let columnas = [];
                    let filas = [];

                    let row = [];

                    // eslint-disable-next-line array-callback-return
                    response.respuesta.map((item, idx) => {
                        columnas.push({ field: 'sucursal-' + idx, headerName: item[0], minWidth: 200, maxWidth: 300, headerAlign: 'center', ...clpPrice });
                        filas.push(item[1]);
                    });

                    var filasObj = { id: 0 };

                    for (var i = 0; i < filas.length; i++) {
                        var newSucursal = "sucursal-" + i;
                        var newValue = filas[i];
                        filasObj[newSucursal] = newValue;

                    }

                    row.push(filasObj);

                    return this.setState({ cuncuna: false, disabledReport: false, columns: columnas, rows: row });
                }

                return this.setState({ cuncuna: false, disabledReport: true, isShowModal: true, messageError: response.mensaje });

            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    render() {
        return (
            <Fragment>
                <h1>Reportes de Asientos Contables</h1>

                <form onSubmit={this.handleSubmit}>
                    <EntriesBox>
                        <EntriesContainer>
                             <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    color="secondary"
                                    label="Seleccionar fecha"
                                    onChange={ (date) => {
                                        const d = new Date(date).toLocaleDateString('en-CA');
                                        this.componentDidMount(d);
                                        this.handleDateChange(d);
                                      }}
                                    renderInput={(params) => <TextField {...params} color="secondary" />}
                                    value={this.state.dateValue}
                                />
                            </LocalizationProvider>
                        </EntriesContainer>

                        <EntriesContainer>
                            <FormControl fullWidth>
                                <InputLabel color="secondary">Seleccionar asiento</InputLabel>
                                <Select
                                    label="Seleccionar asiento"
                                    disabled={this.state.disabledSeat}
                                    onChange={this.handleChangeSeat.bind(this)}
                                    style={{ width: '100%' }}
                                    value={this.state.seatSelected}
                                >
                                    {this.state.seat.map((dt, idx) => {
                                        return <MenuItem key={idx} value={dt.value} style={{ display: "flex", justifyContent: "left", padding: "5px" }}>{dt.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </EntriesContainer>

                        <EntriesContainer style={{ alignSelf: "center" }}>
                            <Button
                                color="secondary"
                                disabled={this.state.disabledButton}
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: (!this.state.disabledButton) ? "#9c27b0" : "" }}
                            >
                                Consultar
                            </Button>
                        </EntriesContainer>
                    </EntriesBox>
                </form>

                <div style={{ height: 700, width: 1200}} hidden={this.state.disabledReport}>
                    <DataGrid 
                        rows={this.state.rows}
                        columns={this.state.columns}              
                        pageSize={this.state.rows.length}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu={true}
                        columnWidth={this.state.columns.length}
                        enableColumnAutosize = {false}
                        components={{
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay
                          }}
                    />
                </div>


                {(this.state.cuncuna) ?
                    <Cuncuna className="loading">

                        <h1>Cargando</h1>
                        <IconLoading src="/images/cuncuna.gif" />
                    </Cuncuna>
                    : null
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isShowModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    closeAfterTransition
                    disableEscapeKeyDown={true}
                    disableAutoFocus={true}
                >
                    <Fade in={this.state.isShowModal}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                            <IconButton
                                aria-label="close"
                                onClick={this.handleClose.bind(this)}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                Ha ocurrido un error
                            </p>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                {this.state.messageError}
                            </p>
                        </Box>
                    </Fade>
                </Modal>
            </Fragment >
        );
    }
}

export default ReportsAccountingComponent;


