import jwtDecode from 'jwt-decode';

/**
 * Guardar token en localStorage
 */
export const saveToken = (token) => {
    localStorage.setItem("token", token);
};

/**
 * Guardar username en localStorage
 */
export const saveDataUser = (username) => {
    localStorage.setItem("username", username);
};

/**
 * Guardar perfil en localStorage
 */
 export const saveProfile = (profile) => {
    localStorage.setItem("profile", profile);
};

/**
 * Guardar modulos en localStorage
 */
 export const saveModules = (modules) => {
    localStorage.setItem("modules", modules);
};

/**
 * Obtiene token desde localStorage
 */
export const getToken = () => {
    return localStorage.getItem("token");
};

/**
 * Obtiene username desde localStorage
 */
export const getUsername = () => {
    return JSON.parse(localStorage.getItem("username")).usuario.username;
};

/**
 * Obtiene email desde localStorage
 */
export const getUserEmail = () => {
    return JSON.parse(localStorage.getItem("username")).usuario.email;
};

/**
 * Elimina token en localStorage
 */
export const deleteToken = () => {
    localStorage.removeItem("token");
};

/**
 * Elimina username en localStorage
 */
export const deleteUsername = () => {
    localStorage.removeItem("username");
};

/**
 * Limpiar session localStorage
 */
export const clearSession = () => {
    localStorage.clear();
};

/**
 * Validar session en localStorage
 */
export const validateSession = () => {
    return ((getUsername() !== undefined && getUsername() !== null));

    /* return ((getToken() !== undefined && getToken() !== null) &&
        (getUsername() !== undefined && getUsername() !== null)); */
};

export const validateTokenExpiration = () => {

    console.log('validateTokenExpiration')

    const { exp } = jwtDecode(getToken().slice(6))

    if ((exp * 1000) > Date.now()) {
        return true
    }

    return false
}