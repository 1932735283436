import React, { Component, Fragment } from 'react';

import { Tooltip, Typography } from '@mui/material';

import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";

import moment from 'moment';
import './style.css';
import { agruparAsientos } from "../utils/asiento";
import { LegendContainer, LegendBlock, LegendItem, LegendBox, LegendTitle, LegendQuantity } from './styles';

const API_URL_EXECUTION =  process.env.REACT_APP_API_URL_EXECUTION;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;

class DashboardComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            errorDays: 0,
            warningDays: 0,
            okayDays: 0,

            currentMonth: 0,

            open: false
        }
    }

    calendarRef = React.createRef();

    componentDidMount() {
        // this.getDataDashboard("");
    }

    handleTooltipOpen = (e) => {
        this.setState({ open: !this.state.open });
    }

    getDataDashboard(date) {
        let endpoint = `${API_URL_EXECUTION}/findAllExecution`;

        let data = [];
        let title = '';
        let asientosPorFecha = [];

        let errorD = 0;
        let okD = 0;
        let warningD = 0;
        let request = '{';
        request = request + '"fecha": "' + date + '"';
        request = request + '}';

        var json = JSON.stringify(request);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json();
            })
            .then((response) => {

                if (response.result === 0) {

                    let respuesta = agruparAsientos(response.respuesta);

                    asientosPorFecha['ok'] = [];
                    asientosPorFecha['nok'] = [];

                    for (let i = 0; i < respuesta.length; i++) {

                        if (this.state.currentMonth === parseInt(moment(respuesta[i].executionDate).format('M'))) {

                            if (respuesta[i].rspCode === 0){
                                //Contabilizo días con asientos exitosos
                                if(!asientosPorFecha['ok'].includes(respuesta[i].executionDate)){
                                    okD = okD + 1;
                                    asientosPorFecha['ok'].push(respuesta[i].executionDate);
                                }
                                
                                //Title en función de status de asiento
                                title = respuesta[i].asientoType
                            }
                                
                            // if (respuesta[i].rspCode === 2)
                            //     warningD = warningD + 1;

                            if (respuesta[i].rspCode === 1){
                                //Contabilizo días con asientos erróneos
                                if(!asientosPorFecha['nok'].includes(respuesta[i].executionDate)){
                                    errorD = errorD + 1;
                                    asientosPorFecha['nok'].push(respuesta[i].executionDate);
                                }

                                //Title en función de status de asiento
                                title = respuesta[i].asientoType+' - '+respuesta[i].glosa;
                            }
                            
                            data.push({
                                title: title,
                                date: moment(respuesta[i].executionDate).format("YYYY-MM-DD"),
                                backgroundColor: (respuesta[i].rspCode === 0) ? "#17B21C" : (respuesta[i].rspCode === 1) ? "#E73B2D" : "#E7B12D",
                                desc: respuesta[i].rspMessage
                            });
                        }
                    }

                }

                this.setState({ events: data, okayDays: okD, warningDays: warningD, errorDays: errorD });
            })
            .catch((error) => console.log('hubo un problema: ', error))
    }

    handleDataSet = (e) => {

        let currentMonth = e.view.currentStart.getMonth() + 1;
        let currentYear = e.view.currentStart.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-01`;

        this.setState({ currentMonth: currentMonth });
        this.getDataDashboard(currentDate);
    }

    renderEventContent = (arg) => {
        if (arg.event._def !== null) {
            return (
                <Tooltip title={<Typography>{arg.event._def.extendedProps.desc}</Typography>} arrow>
                    {renderInnerContent(arg)}
                </Tooltip>
            );
        }

        return null;
    }

    render() {
        return (
            <Fragment>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev next',
                        center: 'title',
                        right: 'today'
                    }}

                    ref={this.calendarRef}
                    initialView="dayGridMonth"
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    locale={esLocale}
                    firstDay={1}
                    eventDisplay='block'
                    height={'750px'}

                    events={this.state.events}
                    eventContent={this.renderEventContent}

                    datesSet={this.handleDataSet}
                />

                <LegendContainer>
                    <LegendBlock>
                        <LegendItem>
                            <LegendBox style={{ backgroundColor: '#17B21C' }}></LegendBox>
                            <LegendTitle>Días OK</LegendTitle>
                            <LegendQuantity>Cantidad:  {this.state.okayDays}</LegendQuantity>
                        </LegendItem>

                        <LegendItem>
                            <LegendBox style={{ backgroundColor: '#E7B12D' }}></LegendBox>
                            <LegendTitle>Días sin cuadrar</LegendTitle>
                            <LegendQuantity>Cantidad:  {this.state.warningDays}</LegendQuantity>
                        </LegendItem>

                        <LegendItem>
                            <LegendBox style={{ backgroundColor: '#E73B2D' }}></LegendBox>
                            <LegendTitle>Días con error</LegendTitle>
                            <LegendQuantity>Cantidad:  {this.state.errorDays}</LegendQuantity>
                        </LegendItem>

                    </LegendBlock>
                </LegendContainer>
            </Fragment>
        );
    }
}



const renderInnerContent = (innerProps) => {
    return (
        <div className='fc-event-main-frame'>
            {innerProps.timeText &&
                <div className='fc-event-time'>{innerProps.timeText}</div>
            }
            <div className='fc-event-title-container'>
                <div className='fc-event-title fc-sticky'>
                    {innerProps.event.title || <Fragment>&nbsp;</Fragment>}
                </div>
            </div>
        </div>
    );
}

export default DashboardComponent;