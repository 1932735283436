import React, { Component, Fragment } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import { TextField, Button, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { EntriesBox, EntriesContainer, Cuncuna, IconLoading } from './styles';
import './style.css';

const API_URL_EXECUTION =  process.env.REACT_APP_API_URL_EXECUTION;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;

class AccountEntriesComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dateValue: null,
            dateError: [],
            personName: '',

            cuncuna: false,

            //SUCURSALES
            office: [],
            officeSelected: "",
            disabledOffice: true,

            //ASIENTOS CONTABLES
            seat: [],
            seatSelected: "",
            disabledSeat: true,

            reprocessDisabled: true,
            isDisabledSeat: true,

            isShowModal: false,
            titleModal: "",
            message: "",
        }
    }

    

    componentDidMount() {
        let endpoint = `${API_URL_EXECUTION}/findFechaError`;
        this.handleDateError(endpoint);
    }

    handleDateError = (endpoint) => {
        let searchDateError = [];

        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            }
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                response.respuesta.map((d) => {
                    let dateMain = new Date(d + 'T00:00:00')
                    return searchDateError.push(dateMain.toLocaleDateString('en-CA'));
                });

                this.setState({ dateError: searchDateError });
            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    handleDateChange = (e) => {
        this.setState({ dateValue: e, disabledSeat: false, disabledOffice: true, officeSelected: "", reprocessDisabled: true });

        let endpoint = `${API_URL_EXECUTION}/findAsientosError`;
        this.handleEntriesError(endpoint, e);
    }

    handleEntriesError = (endpoint, date) => {
        let searchEntriesError = [];

        let dMoment = date.format("YYYY-MM-DD");
        let request = '{';
        request = request + '"fecha":"' + dMoment + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                response.respuesta.map((s) => {
                    return searchEntriesError.push({ 'name': s, 'value': s });
                });

                this.setState({ seat: searchEntriesError });
            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    handleChangeSeat = (e) => {
        this.setState({ seatSelected: e.target.value, disabledOffice: false, reprocessDisabled: true });

        let endpoint = `${API_URL_EXECUTION}/findGlosaError`;
        this.handleOfficeError(endpoint, e.target.value);
    }

    handleOfficeError = (endpoint, seat) => {
        let searchOfficeError = [];

        let dMoment = this.state.dateValue.format("YYYY-MM-DD");

        let request = '{';
        request = request + '"fecha":"' + dMoment + '",';
        request = request + '"asiento":"' + seat + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                response.respuesta.map((o) => {
                    return searchOfficeError.push({ 'name': o, 'value': o });
                });

                this.setState({ office: searchOfficeError });

            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    handleChangeOffice = (e) => {
        this.setState({ officeSelected: e.target.value, reprocessDisabled: false });
    };

    disabledDate = (date) => {
        // Convert the date to a string for comparison
        const dateString = new Date(date).toLocaleDateString('en-CA');
        // Check if the date is in the availableDates array
        if (this.state.dateError.includes(dateString)) {
            return false;
        }
        return true;
     }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ cuncuna: true });

        let endpoint = `${API_URL_EXECUTION}/reprocesarErroneos`;

        let idUsuario = JSON.parse(localStorage.getItem("username")).usuario.idUsuario;
        let date = this.state.dateValue.format("YYYY-MM-DD");
        let seat = this.state.seatSelected;
        let office = this.state.officeSelected;

        let request = '{';
        request = request + '"idUsuario":' + idUsuario + ',';
        request = request + '"fecha":"' + date + '",';
        request = request + '"asiento":"' + seat + '",';
        request = request + '"glosa":"' + office + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                if (response.result === 0) {
                    this.setState({ cuncuna: false, isShowModal: true, titleModal: 'Aviso', message: response.mensaje });
                    this.limpiarFormulario();
                    return;
                }

                return this.setState({ cuncuna: false, isShowModal: true, titleModal: 'Ha ocurrido un error', message: response.mensaje });

            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });


    }

    handleClose = () => {
        this.setState({ isShowModal: false });
    }

    limpiarFormulario = () => {
        this.setState({
            dateValue: null,
            officeSelected: "",
            disabledOffice: true,
            seatSelected: "",
            disabledSeat: true,
            reprocessDisabled: true,
            valorFecha: null,
            isDisabled: true,
            sucursalSeleccionada: "",
            asientoSeleccionado: "",
            isDisabledSeat: true,
        })
    }

    render() {
        return (
            <Fragment>

                <h1>Asientos a reprocesar</h1>

                <form onSubmit={this.handleSubmit}>
                    <EntriesBox id="EntriesBox">
                        <EntriesContainer>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    color="secondary"
                                    label="Seleccionar fecha"
                                    onChange={this.handleDateChange}
                                    renderInput={(params) => <TextField {...params} color="secondary" />}
                                    shouldDisableDate={this.disabledDate}
                                    value={this.state.dateValue}
                                />
                            </LocalizationProvider>
                        </EntriesContainer>

                        <EntriesContainer>
                            <FormControl fullWidth>
                                <InputLabel color="secondary">Seleccionar asiento</InputLabel>
                                <Select
                                    color="secondary"
                                    disabled={this.state.disabledSeat}
                                    label="Seleccionar asiento"
                                    onChange={this.handleChangeSeat.bind(this)}
                                    style={{ width: '100%' }}
                                    value={this.state.seatSelected}
                                >
                                    {this.state.seat.map((dt, idx) => {
                                        return <MenuItem key={idx} value={dt.value} style={{ display: "flex", justifyContent: "left", padding: "5px" }}>{dt.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </EntriesContainer>

                        <EntriesContainer id="EntriesContainer">
                            <FormControl fullWidth>
                                <InputLabel color="secondary">Seleccionar sucursal</InputLabel>
                                <Select
                                    disabled={this.state.disabledOffice}
                                    label="Seleccionar sucursal"
                                    onChange={this.handleChangeOffice.bind(this)}
                                    style={{ width: '100%' }}
                                    value={this.state.officeSelected}
                                >
                                    {this.state.office.map((dt, idx) => {
                                        return <MenuItem key={idx} value={dt.value} style={{ display: "flex", justifyContent: "left", padding: "5px" }}>{dt.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </EntriesContainer>

                    </EntriesBox>

                    <EntriesBox>
                        <EntriesContainer>
                            <Button
                                color="secondary"
                                disabled={this.state.reprocessDisabled}
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: (!this.state.reprocessDisabled) ? "#9c27b0" : "" }}
                            >
                                Reprocesar
                            </Button>
                        </EntriesContainer>
                    </EntriesBox>
                </form>

                {(this.state.cuncuna) ?
                    <Cuncuna className="loading">

                        <h1>Procesando</h1>
                        <IconLoading src="/images/cuncuna.gif" />
                    </Cuncuna>
                    : null
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isShowModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    closeAfterTransition
                    disableEscapeKeyDown={true}
                    disableAutoFocus={true}
                >
                    <Fade in={this.state.isShowModal}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                            <IconButton
                                aria-label="close"
                                onClick={this.handleClose.bind(this)}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                {this.state.titleModal}
                            </p>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                {this.state.message}
                            </p>
                        </Box>
                    </Fade>
                </Modal>
            </Fragment >
        );

    }
}

export default AccountEntriesComponent;

