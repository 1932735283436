import React, { Component, Fragment } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    // GridToolbarFilterButton,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { TextField, Button, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { EntriesBox, EntriesContainer, Cuncuna, IconLoading } from '../styles';

const API_URL_ORACLE = process.env.REACT_APP_API_URL_ORACLE;
const API_URL_EXECUTION =  process.env.REACT_APP_API_URL_EXECUTION;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;

const currencyFormatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
});

const clpPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 100,
        flex: 0.3,
        headerAlign: 'center',
        hide: true,
    },
    {
        field: 'sucursal',
        headerName: 'Sucursal',
        width: 200,
        flex: 0.3,
        headerAlign: 'center',
    },
    {
        field: 'desglose',
        headerName: 'Desglose',
        width: 200,
        flex: 0.3,
        headerAlign: 'center',
    },
    {
        field: 'monto',
        headerName: 'Monto',
        width: 300,
        flex: 0.3,
        headerAlign: 'center',
        ...clpPrice
    }
];

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            <GridToolbarExport csvOptions={{ delimiter: ";" }} />
        </GridToolbarContainer>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <div style={{ margin: 'auto' }}>Sin datos</div>
    );
}


class ReportsQuadratureComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dateValue: null,
            disabledButton: true,

            cuncuna: false,
            disabledReport: true,
            disabledOffice: false,

            office: [],
            officeSelected: "",

            isShowModal: false,
            messageError: "",

            rows: [],
        }
    }

    componentDidMount() {
        let endpoint = `${API_URL_EXECUTION}/findGlosa`;
        this.handleOffice(endpoint);
    }

    handleOffice = (endpoint) => {
        let searchOffice = [];
        searchOffice.push({ 'name': 'TODAS', 'value': 'TODAS' });
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            }
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                response.respuesta.map((o) => {

                    return searchOffice.push({ 'name': o, 'value': o });
                });

                this.setState({ office: searchOffice });
            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    handleDateChange = (e) => {
        this.setState({ dateValue: e, disabledButton: (this.state.seatSelected !== "") ? false : true });
    }

    handleChangeOffice = (e) => {
        this.setState({ officeSelected: e.target.value, disabledButton: false });
    }

    handleClose = () => {
        this.setState({ isShowModal: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ cuncuna: true });

        let searchData = [];
        let filas = [];

        let endpoint = `${API_URL_ORACLE}/informeRecaudacion`;

        let request = '{';
        request = request + '"fecha": "' + this.state.dateValue + '", ';
        request = request + '"glosa": "' + this.state.officeSelected.toUpperCase() + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {

                if (response.result === 0) {
                    searchData = response.respuesta;

                    searchData.map((item) => {
                        return filas.push({ id: item.id+item.sucu, sucursal: item.sucu, desglose: item.desglose, monto: item.monto });
                    });

                    return this.setState({ cuncuna: false, disabledReport: false, rows: filas });
                }

                return this.setState({ cuncuna: false, disabledReport: true, isShowModal: true, messageError: response.mensaje });

            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    }

    render() {
        return (
            <Fragment>
                <h1>Reporte de Asiento Recaudación</h1>

                <form onSubmit={this.handleSubmit}>
                    <EntriesBox>
                        <EntriesContainer>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                <DatePicker
                                    color="secondary"
                                    label="Seleccionar fecha"
                                    onChange={ (date) => {
                                        const d = new Date(date).toLocaleDateString('en-CA');
                                        this.handleDateChange(d);
                                      }}
                                    renderInput={(params) => <TextField {...params} color="secondary" />}
                                    value={this.state.dateValue}
                                />
                            </LocalizationProvider>
                        </EntriesContainer>

                        <EntriesContainer>
                            <FormControl fullWidth>
                                <InputLabel color="secondary">Seleccionar sucursal</InputLabel>
                                <Select
                                    label="Seleccionar sucursal"
                                    disabled={this.state.disabledOffice}
                                    onChange={this.handleChangeOffice.bind(this)}
                                    style={{ width: '100%' }}
                                    value={this.state.officeSelected}
                                >
                                    {this.state.office.map((dt, idx) => {
                                        return <MenuItem key={idx} value={dt.value} style={{ display: "flex", justifyContent: "left", padding: "5px" }}>{dt.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </EntriesContainer>

                        <EntriesContainer style={{ alignSelf: "center" }}>
                            <Button
                                color="secondary"
                                disabled={this.state.disabledButton}
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: (!this.state.disabledButton) ? "#9c27b0" : "" }}
                            >
                                Consultar
                            </Button>
                        </EntriesContainer>
                    </EntriesBox>
                </form>

                <div style={{ height: 700, width: '100%' }} hidden={this.state.disabledReport}>
                    <DataGrid
                        rows={this.state.rows}
                        columns={columns}
                        pageSize={10}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu={true}
                        components={{
                            Toolbar: CustomToolbar,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                    />
                </div>

                {(this.state.cuncuna) ?
                    <Cuncuna className="loading">

                        <h1>Cargando</h1>
                        <IconLoading src="/images/cuncuna.gif" />
                    </Cuncuna>
                    : null
                }

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isShowModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    closeAfterTransition
                    disableEscapeKeyDown={true}
                    disableAutoFocus={true}
                >
                    <Fade in={this.state.isShowModal}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                            <IconButton
                                aria-label="close"
                                onClick={this.handleClose.bind(this)}
                                style={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                Mensaje del sistema
                            </p>

                            <p style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}>
                                {this.state.messageError}
                            </p>
                        </Box>
                    </Fade>
                </Modal>
            </Fragment>
        );
    }
}

export default ReportsQuadratureComponent;