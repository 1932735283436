import React, { Fragment, useState } from "react";
import InputComponent from "../tags/input";
import ButtonComponent from "../tags/button";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";

import { saveDataUser, saveProfile, saveModules } from "../utils/session";

import {
  Cuncuna,
  IconLoading,
  LoginContainer,
  LoginMain,
  LoginForm,
  LoginInfo,
  LoginInfoContent,
  LoginTitleInfo,
  LogoRipley,
} from "./styles";

const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;
//console.log(API_URL_AUTH);
//console.log(APP_API_VALUE);

const LoginApp = ({ history }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const [isShowModal, setModal] = useState(false);
  const [cuncuna, setCuncuna] = useState(false);
  const [messageError, setMessage] = useState("");

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  const { username, password } = user;

  const handleChange = ({ target }) => {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCuncuna(true);

    var endpoint = `${API_URL_AUTH}/login`;
    handleSesionLogin(endpoint);
  };

  const handleSesionLogin = (endpoint) => {
    let request = "{";
    request = request + '"username": "' + username + '",';
    request = request + '"password": "' + password + '"';
    request = request + "}";

    var json = JSON.stringify(request);
 
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-api-key": APP_API_VALUE,
      },
      body: JSON.parse(json),
    };

    fetch(endpoint, requestOptions)
      .then((result) => {
        return result.json();
      })
      .then((response) => {
        console.log(response);

        if (response.result === 0) {
          setCuncuna(false);
          saveDataUser(JSON.stringify(response.respuesta));
          saveProfile(JSON.stringify(response.respuesta.usuario.perfil));
          saveModules(
            JSON.stringify(response.respuesta.usuario.perfil.listaModulos)
          );

          history.push("/home");
        }

        setCuncuna(false);
        setMessage(response.mensaje);
        handleOpen();
      })
      .catch((error) => {
        console.log("Hubo un problema con la petición Fetch: " + error.message);
      });
  };

  const isFormValid = () => {
    if (
      username !== undefined &&
      username !== null &&
      username !== "" &&
      password !== undefined &&
      password !== null &&
      password !== ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <LoginContainer>
          <LoginMain id="LoginMain">
            <LoginInfo id="LoginInfo">
              <LoginInfoContent id="LoginInfoContent">
                <LogoRipley src="/images/logo-ripley.png" alt="Logo Ripley" />
                <LoginTitleInfo>Bienvenidos!</LoginTitleInfo>
              </LoginInfoContent>
            </LoginInfo>
            <LoginForm>
              <InputComponent
                id="outlined-basic-text"
                autoComplete="off"
                size="small"
                label="Usuario"
                variant="outlined"
                color="secondary"
                placeholder="Usuario"
                type="text"
                value={username}
                onChange={handleChange}
                name="username"
                sx={{ margin: "5px" }}
              />

              <InputComponent
                id="outlined-basic-password"
                autoComplete="off"
                size="small"
                label="Password"
                variant="outlined"
                color="secondary"
                placeholder="Password"
                type="password"
                value={password}
                onChange={handleChange}
                name="password"
                sx={{ margin: "5px" }}
              />

              <ButtonComponent
                disabled={!isFormValid()}
                variant="contained"
                color="secondary"
                type="submit"
                value="Ingresar"
                sx={{
                  width: "50%",
                  alignSelf: "center",
                  marginTop: "20px",
                  marginBottom: "28px",
                }}
              ></ButtonComponent>
            </LoginForm>
          </LoginMain>
        </LoginContainer>
      </form>

      {cuncuna ? (
        <Cuncuna className="loading">
          <h1>Procesando</h1>
          <IconLoading src="/images/cuncuna.gif" />
        </Cuncuna>
      ) : null}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShowModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
      >
        <Fade in={isShowModal}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <p
              style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}
            >
              Ha ocurrido un error
            </p>

            <p
              style={{ fontSize: "16px", fontWeight: "500", color: "#464646" }}
            >
              {messageError}
            </p>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default LoginApp;
