import React from "react";
import { Link } from "react-router-dom";
import { Button } from '@mui/material';

import './style.css';

import { validateSession, getUsername, getUserEmail, clearSession } from '../utils/session';

import {
    NavegationMenuContainer,
    NavegationMenu,
    UserContainer,
    UserName,
    UserMail,
    ContainerDataUser,
    IconUser,
    NavegationList,
    NavegationListItem,
    BlockButtonSignOff,
    NavegationListSubMenu,
    NavegationListSubMenuItem

} from "./styles";

const API_URL_AUTH  = process.env.REACT_APP_API_URL_AUTH;
const APP_API_VALUE = process.env.REACT_APP_API_VALUE;

class NavegationComponent extends React.Component {

    constructor() {
        super();

        this.state = {
            hiddenMenu: true,

            accessModule: []
        }
    }

    componentDidMount() {
        this.enableFunctionality();
    }

    logout = () => {
        let endpoint = `${API_URL_AUTH}/logout`;

        let userId = JSON.parse(localStorage.getItem("username")).usuario.idUsuario;

        let request = '{';
        request = request + '"id": "' + userId + '"';
        request = request + '}';

        var json = JSON.stringify(request);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "x-api-key": APP_API_VALUE,
            },
            body: JSON.parse(json)
        };

        fetch(endpoint, requestOptions)
            .then((result) => {
                return result.json()
            })
            .then((response) => {
                clearSession();
                window.location = "/";

            })
            .catch((error) => {
                console.log("Hubo un problema con la petición Fetch: " + error.message);
            });
    };

    enableFunctionality() {
        let x = [];

        let modules = JSON.parse(localStorage.getItem("modules"));

        modules.map((item) => {
            return x.push(item.idModulo);
        });

        this.setState({ accessModule: x });
    }

    render() {
        return (
            <NavegationMenuContainer id="NavegationMenuContainer">
                <NavegationMenu id="NavegationMenu">
                    <UserContainer>
                        <IconUser src="images/login.svg" />
                        <ContainerDataUser>
                            <UserName>
                                {validateSession()
                                    ? getUsername().toUpperCase()
                                    : (window.location = "/")}
                            </UserName>
                            <UserMail>{validateSession()
                                ? getUserEmail()
                                : (window.location = "/")}</UserMail>
                        </ContainerDataUser>
                    </UserContainer>

                    <NavegationList>
                        <Link to={{ pathname: "/home" }} style={{ textDecoration: "none" }} hidden={!this.state.accessModule.includes(4)}>
                            <NavegationListItem>Inicio</NavegationListItem>
                        </Link>

                        <Link to={{ pathname: "/home/accountentries" }} style={{ textDecoration: "none" }} hidden={!this.state.accessModule.includes(1)}>
                            <NavegationListItem>Asientos a reprocesar</NavegationListItem>
                        </Link>

                        <div style={{ cursor: "pointer" }}>
                            <NavegationListSubMenu id="myMenu" onClick={(e) => {
                                e.preventDefault();
                                this.state.hiddenMenu ? this.setState({ hiddenMenu: false }) : this.setState({ hiddenMenu: true })
                            }}>
                                Reportes
                                <div id="menuItem" hidden={this.state.hiddenMenu}>
                                    <Link to={{ pathname: "/home/reports/accounting" }} style={{ textDecoration: "none" }} hidden={!this.state.accessModule.includes(2)}>
                                        <NavegationListSubMenuItem>
                                            Reportes de asientos contables
                                        </NavegationListSubMenuItem>
                                    </Link>
                                    <Link to={{ pathname: "/home/reports/logs" }} style={{ textDecoration: "none" }} hidden={!this.state.accessModule.includes(3)}>
                                        <NavegationListSubMenuItem>
                                            Reportes de Logs de carga
                                        </NavegationListSubMenuItem>
                                    </Link>
                                    <Link to={{ pathname: "/home/reports/quadrature" }} style={{ textDecoration: "none" }} hidden={!this.state.accessModule.includes(5)}>
                                        <NavegationListSubMenuItem>
                                            Reporte de asiento recaudación
                                        </NavegationListSubMenuItem>
                                    </Link>
                                </div>
                            </NavegationListSubMenu>
                        </div>

                    </NavegationList>


                    <BlockButtonSignOff id="botonOFF">
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={this.logout}
                            className="colorButton"
                        >
                            Cerrar sesión
                        </Button>
                    </BlockButtonSignOff>
                </NavegationMenu>



            </NavegationMenuContainer>
        );
    }

}

export default NavegationComponent;